<script setup>
  import { ref, watch, computed } from 'vue';

  const props = defineProps({
    value: String,
    ariaLive: {
      type: String,
      default: 'assertive',
      validator: (value) => {
        return ['assertive', 'polite', 'off'].indexOf(value) !== -1;
      },
    },
    hidden: {
      type: Boolean,
      default: true,
    },
  });

  const isVisuallyHidden = computed(() =>
    props.hidden ? 'visually-hidden' : null,
  );
</script>

<template>
  <span
    :class="[isVisuallyHidden]"
    :aria-live="ariaLive"
    role="alert"
    aria-relevant="additions"
    aria-atomic="true"
  >
    {{ value }}
  </span>
</template>

<style lang="scss" scoped></style>
