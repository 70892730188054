import {
  exceptionsHandler,
  UnknownExceptionError,
} from './exceptions/exceptions';

// Class representing a service abstraction.
class ServiceAbstraction {
  static identifier = null; // Unique identifier for the service method.
  static baseUrlPath = null; // Relative URL path for the service.
  static isCdeEnabled = false; // Flag indicating whether CDE (Content Delivery Environment) is enabled.

  /**
   * Creates an instance of ServiceAbstraction.
   * @param {InstanceType} httpLayer - HTTP layer instance for making requests.
   * @param {Object} configManager - Configuration manager for accessing API and HTTP layer configurations.
   */
  constructor(httpLayer, configManager) {
    this.httpLayer = httpLayer;
    this.config = configManager.getApiConfig;
    this.httpLayer.baseURL = this._getBaseUrl();

    // Update global http layer options.
    this.httpLayer.setConfig = configManager.getHttpLayerConfig;
  }

  /**
   * Ensures that the service method has a unique identifier.
   * @returns {string|null} - The unique identifier for the service method.
   * @throws {Error} - If the identifier is missing.
   */
  static getIdentifier() {
    if (!this?.identifier) {
      console.error(`Error: An API service is missing a unique identifier`);
      throw new Error('Error: An API service is missing a unique identifier');
    }

    return this.identifier;
  }

  /**
   * Defines default input parameters for the service method.
   * @returns {Object} - Default input parameters.
   */
  inputParams(inputParams) {
    return {};
  }

  /**
   * Defines the default behavior of making a service call.
   * @returns {Object} - An empty array (default behavior).
   */
  doCall(inputParams) {
    return {};
  }

  /**
   * Determines the base URL based on whether CDE is enabled.
   * @returns {string} - The base URL for the service.
   */
  _getBaseUrl() {
    if (this.constructor.isCdeEnabled) {
      return this.config.baseUrlCDE + this.constructor.baseUrlPath;
    }

    return this.config.baseURL + this.constructor.baseUrlPath;
  }

  /**
   * Returns the HTTP layer instance for making requests.
   * @returns {Object} - The HTTP layer instance.
   */
  _httpLayer() {
    return this.httpLayer;
  }

  /**
   * Invokes the service method.
   * @returns {Promise} - A Promise that resolves to the response data or an error.
   */
  async _invoke() {
    const inputParams = this.inputParams();

    try {
      const possiblePromise = this.doCall(inputParams);

      // If the 'possiblePromise' is not an instance of a Promise, we can confidently infer that it's a simulated request (mocks).
      if (!(possiblePromise instanceof Promise)) {
        return this.output(possiblePromise);
      }

      const response = await possiblePromise;
      const data = response.data;

      return this.output(data);
    } catch (error) {
      if (error.response) {
        // Handle error when response is received from the server.
        return exceptionsHandler(error.response);
      } else if (error.request) {
        // Handle other request setup errors.
        console.error('Error no response received');
        throw new UnknownExceptionError();
      } else {
        // Handle other request setup errors.
        console.error('Error in request setup');
        throw new UnknownExceptionError();
      }
    }
  }
}

export default ServiceAbstraction;
