import { useCardinalStore } from '@/stores/CardinalStore';
import { inject, resolveComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useLocaleStore } from '@/stores/LocaleStore';
import { storeToRefs } from 'pinia';
import emitter from '@/composables/event-bus';
import { getExceptions } from 'api';
import { CARDINAL_STEP_UP_POST_MESSAGE_ORIGIN_URL } from '@/configs/cardinal-config.js';

export function useCardinalPostMessageHandler() {
  const cardinalStore = useCardinalStore();
  const modalManager = inject('modalManager');
  const localeStore = useLocaleStore();
  const router = useRouter();
  const { getLocale } = storeToRefs(localeStore);
  const loaderHandler = inject('loaderHandler');
  const { MessageExceptionError, ValidationExceptionErrors } = getExceptions();
  const TheModalGeneralError = resolveComponent('TheModalGeneralError');
  const translations = inject('translations');

  let stepUpListenerTimeout = null;

  function startStepUpListener() {
    window.addEventListener('message', postMessageStepUp, false);
    initiateTimeout(); // Start the timeout when the listener is added.
  }

  function stopStepUpListener() {
    clearTimeout(stepUpListenerTimeout);
    window.removeEventListener('message', postMessageStepUp, false);
  }

  function initiateTimeout() {
    stepUpListenerTimeout = setTimeout(async () => {
      await handleTimeout();
    }, 300000); // Set timeout for 5 minutes.
  }

  async function handleTimeout() {
    await _errorHandler('cardinal_step_up_time_out_error');
    stopStepUpListener();
  }

  async function postMessageStepUp(event) {
    if (event.origin === CARDINAL_STEP_UP_POST_MESSAGE_ORIGIN_URL) {
      stopStepUpListener(); // Stop the listener and clear the timeout.

      await modalManager.closeModal();
      const data = JSON.parse(event.data);

      try {
        await cardinalStore.authenticateService(data?.TransactionId);
      } catch (e) {
        _resets();

        if (
          e instanceof MessageExceptionError ||
          e instanceof ValidationExceptionErrors
        ) {
          // Specific error for when MC card link failed due to 'AccountNotQualifiedException'.
          if (e.name === 'AccountNotQualifiedException') {
            await modalManager.openModal(TheModalGeneralError, {
              props: {
                title: translations.getOne(
                  e.title,
                  'We are unable to link your credit card',
                ),
                message: translations.getOne(
                  e.message,
                  "Ensure the selected 'Country/Region of Issue' matches the origin of your card. Please note that only cards issued in the approved regions can be linked and some cards may have restrictions imposed by the issuing bank.",
                ),
              },
            });

            return;
          }

          await _errorHandler(e.message);
        }

        return;
      }

      await successHandler();
    }
  }

  async function successHandler() {
    loaderHandler.stop('userStore::loader::addCard');

    _resets();

    await router.push(
      localeStore.redirectToRoute({
        name: 'ManageCards',
        params: { locale: getLocale.value },
      }),
    );

    emitter.$emit('alert::success-alert::open', {
      message: 'add_card_action_success_text',
    });
  }

  async function _errorHandler(msg) {
    loaderHandler.stop('userStore::loader::addCard');

    _resets();
    emitter.$emit('alert::error-alert::open', { message: msg });
  }

  function _resets() {
    cardinalStore.$reset();
    emitter.$emit('linkCardView::cardLinkForm::resetFormData');
  }

  return {
    startStepUpListener,
    stopStepUpListener,
    successHandler,
  };
}
