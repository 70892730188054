class ConfigManager {
  /**
   * Constructor for ConfigManager class.
   * @param {Object} config - Configuration object with default values.
   */
  constructor({
    baseUrlCDE = null,
    baseURL = null,
    mocks = false,
    httpLayer = {
      validateStatus: (status) => status >= 200 && status < 300,
    },
  } = {}) {
    // Initialize API and HTTP client configurations.
    this.apiConfig = this._filterConfig({ baseUrlCDE, baseURL, mocks });
    this.httpClientConfig = this._filterConfig(httpLayer);
  }

  /**
   * Setter for API configuration.
   * @param {Object} config - New API configuration.
   */
  set setApiConfig(config) {
    this.apiConfig = config;
  }

  /**
   * Setter for HTTP layer configuration.
   * @param {Object} config - New HTTP client configuration.
   */
  set setHttpLayerConfig(config) {
    this.httpClientConfig = { ...this.httpClientConfig, ...config };
  }

  /**
   * Getter for API configuration.
   * @returns {Object} - Current API configuration.
   */
  get getApiConfig() {
    return this.apiConfig;
  }

  /**
   * Getter for HTTP layer configuration.
   * @returns {Object} - Current HTTP layer configuration.
   */
  get getHttpLayerConfig() {
    return this.httpClientConfig;
  }

  /**
   * Filters configuration based on presence of httpClient property.
   * @param {Object} config - Input configuration object.
   * @returns {Object} - Filtered configuration object.
   */
  _filterConfig(config) {
    return config && config.httpClient ? {} : config || {};
  }
}

export default ConfigManager;
