/**
 * Base class for custom exceptions with common functionality.
 */
class BaseExceptionError extends Error {
  constructor(data) {
    super(data?.message || null);

    this.name = data?.name;
    this.title = data?.title;
    this.message = data?.message;
  }
}

/**
 * SilentExceptionError which is a specific type of custom error representing a silent exception.
 */
class SilentExceptionError extends BaseExceptionError {
  constructor(data) {
    super(data);

    this.name = data.name ?? 'SilentExceptionError';
  }
}

/**
 * UnauthorizedExceptionError which is a specific type of custom error representing an unauthorized exception.
 */
class UnauthorizedExceptionError extends BaseExceptionError {
  constructor(data) {
    super(data);

    this.name = data.name ?? 'UnauthorizedExceptionError';
  }
}

/**
 * MessageExceptionError which is a specific type of custom error representing a message-related exception.
 */
class MessageExceptionError extends BaseExceptionError {
  constructor(data) {
    super(data);

    this.name = data.name ?? 'MessageExceptionError';
  }
}

/**
 * ValidationExceptionErrors which is a specific type of custom error representing a validation-related exception.
 */
class ValidationExceptionErrors extends BaseExceptionError {
  constructor(data) {
    super({ ...data, message: null });

    this.name = data.name ?? 'ValidationExceptionErrors';
    this.messages = data.messages;
  }
}

/**
 * UnknownExceptionError which is a specific type of custom error representing an unknown exception.
 */
class UnknownExceptionError extends BaseExceptionError {
  constructor(data) {
    super(data);

    this.name = data.name ?? 'UnknownExceptionError';
  }
}

// exceptionsHandler is a function that handles different types of responses (response).
const exceptionsHandler = (response) => {
  const data = response.data;

  // Unauthorized: If response status code is 401, throw an UnauthorizedExceptionError.
  if (response.status === 401) {
    throw new UnauthorizedExceptionError(data);
  }

  // Known errors (status code 400) handling.
  if (response.status === 400) {
    // If the status is 'message_error', throw a MessageExceptionError with the provided error message.
    if (data.type === 'message_error') {
      throw new MessageExceptionError(data);
    }

    // If the status is 'validation_error', throw a ValidationExceptionErrors with the provided errors.
    if (data.type === 'validation_error') {
      throw new ValidationExceptionErrors(data);
    }
  }

  // Unknown errors: If response status code is not 200, throw an UnknownExceptionError.
  throw new UnknownExceptionError(data);
};

export {
  exceptionsHandler,
  SilentExceptionError,
  UnauthorizedExceptionError,
  MessageExceptionError,
  ValidationExceptionErrors,
  UnknownExceptionError,
};
